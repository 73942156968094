import template from './referral_agreement_link_template.html'
import { clickModalAgreementLink } from 'ReferralAgreement/track_referral_agreement'

const ReferralAgreementLinkModule = angular
  .module('referralAgreementLink', [])
  .component('referralAgreementLink', {
    bindings: {
      source: '<',
      isLargeContractor: '<',
      isClbAgreement: '<',
    },
    template,
    controller: [
      'urlConstants',
      function ({ referralAgreement }) {
        const params = [
          `from=${window.location.pathname}`,
          `source=${this.source}`,
          `isLargeContractor=${this.isLargeContractor}`,
        ]

        if (this.isClbAgreement) {
          params.push('type=CLB_v1_2025')
        }

        this.referralAgreementUrl = `${referralAgreement}?${params.join('&')}`
        this.clickModalAgreementLink = clickModalAgreementLink
      },
    ],
  }).name

export default ReferralAgreementLinkModule
